import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import ReviewsSlider from '../components/ReviewsSlider'
import Faq from '../components/Faq/index'
import PageTitle from '../components/Blocks/PageTitle'
import EarlyLeaseTerminationProgramForm from '../forms/EarlyLeaseTerminationProgramForm'
import { useLocation } from '@reach/router'

const PageTemplate = ({ title, content, faq, reviews }) => {
  const location = useLocation();
  const path = location.pathname;
  const isExisting = path.includes('dm-early-lease-termination-program');

  let firstBlock = '', secondBlock = '';
  const someString = '<div class="wp-block-media-text alignfull has-media-on-the-right is-stacked-on-mobile">';
  const index = content.indexOf(someString);
  firstBlock = content.slice(0, index);
  secondBlock = content.slice(index);

  return (
    <div className="w-full">
      <PageTitle title={title} />
      <div className={'transition-all page-content'}
          dangerouslySetInnerHTML={{ __html: firstBlock }}
          />
      <div class="wp-block-group alignfull has-grey-background is-layout-flow wp-block-group-is-layout-flow">
        <EarlyLeaseTerminationProgramForm type={isExisting ? "existing" : "default"}/>
      </div>
      <div className={'transition-all page-content'}
          dangerouslySetInnerHTML={{ __html: secondBlock }}
          />
      <ReviewsSlider />
      <div aria-hidden="true" class="wp-block-spacer wp-block-spacer-reviews"></div>
      {faq?.title && <Faq title={faq?.title} items={faq?.faqs} />}
    </div>
  )
}

const EarlyLeaseTermination = ({ data, pageContext }) => {
  const { wpPage: page } = data;
  return (
    <div>
      <Layout pageContext={pageContext}>
        <Seo seo={page.seo} />
        <PageTemplate title={page.title} content={page.content} faq={page.faq}/>
      </Layout>
    </div>
  )
}

export default EarlyLeaseTermination

export const pageQuery = graphql`
  query Reviews($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      seo {
        fullHead
        schema {
          raw
        }
      }
      faq {
        title
        faqs {
          body
          title
        }
      }
    }
  }
`
